import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { FTSTheme } from "../../App";
import ThreeDotsMenu from "../../components/ThreeDotsMenu/ThreeDotsMenu";
import { EProjectStatusDescription } from "../../enum/EProjectStatus";
import { IProjectSummaryDto } from "../../interfaces/DTO/IProjectSummaryDto";
import OpenIcon from "../Configurator/Icons/OpenIcon.svg";
import "./Projects.css";
import FitText from "../../components/FitText/FitText";
import { BASIC_GLOBAL_DATE_FORMAT } from "../../constants/DateFormatTypes";
import useProjectHandler from "../../hooks/useProjectHandler";
import { ROWS_PER_PAGE } from "../../constants/NumberValues";
import { PROJECTS_TABLE } from "../../constants/TableHeads";

interface IProps {
  projectsSummary: IProjectSummaryDto[];
  checked: string[];
  setChecked: Dispatch<SetStateAction<string[]>>;
  available: boolean;
}

interface IHeaderCell {
  id: string;
  label: string;
  width: string;
  sortable: boolean;
}

type Order = "asc" | "desc";

const ProjectsTable: React.FC<IProps> = ({ projectsSummary, available, setChecked, checked }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const { handleOpenProject } = useProjectHandler();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>(PROJECTS_TABLE.CREATED_AT);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headerCells: readonly IHeaderCell[] = [
    { id: PROJECTS_TABLE.NAME, label: "projects.table.header.name", width: "", sortable: true },
    { id: PROJECTS_TABLE.QUANTITY, label: "projects.table.header.number_of_products", width: "", sortable: false },
    { id: PROJECTS_TABLE.CREATED_AT, label: "projects.table.header.create_date", width: "", sortable: true },
    { id: PROJECTS_TABLE.LAST_MODIFIED, label: "projects.table.header.last_modified", width: "", sortable: true },
    { id: PROJECTS_TABLE.STATUS, label: "projects.table.header.status", width: "", sortable: true },
    { id: PROJECTS_TABLE.EMPTY, label: "", width: "", sortable: false },
  ];

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProjectSummaryDto) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedProjects = [...projectsSummary].sort((a, b) => {
    if (orderBy === PROJECTS_TABLE.CREATED_AT || orderBy === PROJECTS_TABLE.LAST_MODIFIED) {
      const dateA = moment(a[orderBy]);
      const dateB = moment(b[orderBy]);
      return (dateA.isBefore(dateB) ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
    return (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1);
  });

  return (
    <>
      <TableContainer
        sx={{
          "& .MuiTable-root": {
            border: "1px solid #DADADA",
            borderRadius: 4,
          },
        }}
      >
        <Table className="designSummary__table">
          <TableHead className={`projects__${ftsTheme.name}_table_head`}>
            <TableRow>
              <TableCell className="projects__table_row_cell_checkbox">
                {(available && (
                  <Checkbox
                    sx={{
                      color: "#333333",
                      "&.Mui-checked": {
                        color: "#333333",
                      },
                    }}
                    onChange={(event) => {
                      if (event.target.checked) {
                        const idArray = projectsSummary.map((item) => item.id);
                        setChecked(idArray);
                      } else {
                        setChecked([]);
                      }
                    }}
                  />
                )) || <div className="projects__table_cell_spacing"></div>}
              </TableCell>
              {headerCells.map((headerCell: IHeaderCell) => (
                <TableCell
                  className={`projects__table_header_cell ${headerCell.id === PROJECTS_TABLE.NAME ? PROJECTS_TABLE.NAME : ""}`}
                  width={headerCell.width}
                  key={headerCell.id}
                >
                  {headerCell.sortable ? (
                    <TableSortLabel
                      active={orderBy === headerCell.id}
                      direction={orderBy === headerCell.id ? order : "asc"}
                      onClick={(evt) => handleRequestSort(evt, headerCell.id as keyof IProjectSummaryDto)}
                    >
                      {t(headerCell.label)}
                    </TableSortLabel>
                  ) : (
                    t(headerCell.label)
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProjects.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="recentProjects__listNotification">
                    <Typography sx={{ marginTop: "15px" }}>{t("projects_youHaveNotCreatedOneYet")}</Typography>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {sortedProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project) => (
              <TableRow key={project.id}>
                <TableCell className="projects__table_row_cell_checkbox">
                  {available && (
                    <Checkbox
                      sx={{
                        color: "#333333",
                        "&.Mui-checked": {
                          color: "#333333",
                        },
                      }}
                      checked={checked.includes(project.id)}
                      onChange={() => {
                        if (checked.includes(project.id)) {
                          setChecked((prev) => prev.filter((item) => item !== project.id));
                        } else {
                          setChecked((prev) => [...prev, project.id]);
                        }
                      }}
                    />
                  )}
                </TableCell>
                <TableCell className="projects__table_row_cell name">{project.name}</TableCell>
                <TableCell className="projects__table_row_cell">{project.productDesignsQuantity}</TableCell>
                <TableCell className="projects__table_row_cell">
                  {moment(project.createdAt).format(BASIC_GLOBAL_DATE_FORMAT)}
                </TableCell>
                <TableCell className="projects__table_row_cell">
                  {moment(project.lastModified).format(BASIC_GLOBAL_DATE_FORMAT)}
                </TableCell>
                <TableCell className="projects__table_row_cell">
                  {t(EProjectStatusDescription.get(project.status)!)}
                </TableCell>
                <TableCell>
                  {available && (
                    <Box className="projects__table_cell_with_buttons">
                      <Button
                        onClick={() => handleOpenProject(project.id)}
                        sx={{ width: 124, height: 40, borderColor: "#DBDBDB" }}
                        startIcon={
                          <ReactSVG
                            src={OpenIcon}
                            beforeInjection={(svg) => {
                              svg.setAttribute("style", `width: 16px; height: 16px; fill: ${ftsTheme.icon.color};`);
                            }}
                          />
                        }
                        variant="outlined"
                        color="primary"
                      >
                        <FitText sx={{ fontSize: 14, fontWeight: 500, color: ftsTheme.icon.color }}>
                          {t("open")}
                        </FitText>
                      </Button>
                      <Box sx={{ paddingLeft: "24px", display: "initial" }}>
                        <ThreeDotsMenu
                          data={project}
                          enableNameEdit={true}
                        />
                      </Box>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {projectsSummary.length && (
        <TablePagination
          rowsPerPageOptions={[ROWS_PER_PAGE]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          component="div"
          count={projectsSummary.length}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
};

export default ProjectsTable;
